import classnames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'

import './Button.css'

const Button = ({
  type = 'primary',
  className,
  enabled = true,
  active,
  icon,
  iconAlignment,
  onClick = () => {},
  href,
  text,
  title,
  'aria-label': ariaLabel = '',
  textOnHover = false,
  textAlways = false,
}) => {
  const classes = classnames(
    'button',
    {
      active: active,
      disabled: !enabled,

      with_icon: icon !== undefined && !textAlways,
      text_on_hover: textOnHover,
      [`button--${type}`]: type,
    },
    className
  )

  const iconClasses = classnames('icon', {
    icon_with_text: !textOnHover,
    icon_without_text: textOnHover,

    icon_only: !textOnHover && text === undefined,
  })

  const content = (
    <span
      className={`content${
        iconAlignment ? ` content-with-icon--${iconAlignment}` : ''
      }`}
    >
      {icon && <div className={iconClasses}>{icon}</div>}
      <span className={'text'}>{text}</span>
    </span>
  )

  if (href) {
    return (
      <a
        className={classes}
        aria-label={ariaLabel}
        href={href}
        onClick={onClick}
      >
        {content}
      </a>
    )
  }
  return (
    <button
      className={classes}
      title={title}
      aria-label={ariaLabel}
      onClick={onClick}
      disabled={!enabled}
    >
      {content}
    </button>
  )
}

Button.propTypes = {
  type: PropTypes.string,
  className: PropTypes.string,
  enabled: PropTypes.bool,
  active: PropTypes.bool,
  icon: PropTypes.element,
  iconAlignment: PropTypes.string,
  href: PropTypes.string,
  onClick: PropTypes.func,
  text: PropTypes.string,
  title: PropTypes.string,
  textAlways: PropTypes.bool,
  'aria-label': PropTypes.string,
  textOnHover: PropTypes.bool,
}

export default Button
